import { UNAVAILABLE_IMAGE_URL } from '@stories/product-image/constants'

import { generateImageUrl, presetImageConfigs } from './get-cloudflare-img-url'

interface ImageLoaderProps {
  src: string
  width: number
  quality?: number
}

// Add function to determine if an image should use thumbnail config
function shouldUseThumb(width: number): boolean {
  // If width is small enough, use thumb config
  // 600px matches the productThumb preset width
  return width <= 600
}

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src
}

export function cloudflareImageLoader({ src, width, quality }: ImageLoaderProps): string {
  // For the fallback image, return directly
  if (src === UNAVAILABLE_IMAGE_URL) {
    return src
  }

  // Select the appropriate preset config based on image dimensions
  const baseConfig = shouldUseThumb(width) ? presetImageConfigs.productThumb : presetImageConfigs.productFull

  // Override with specific width and quality if provided
  const imageConfig = {
    ...baseConfig,
    width, // Always use the requested width from Next.js
    quality: quality || 100
  }

  // Handle static images from public folder
  if (src.startsWith('/static/')) {
    // Create a full URL that Cloudflare can optimize
    const fullUrl = `${process.env.WWW_BASE_URL || 'https://www.eaze.com'}${src}`
    return generateImageUrl(fullUrl, imageConfig)
  }

  // Normalize the src path if needed
  const normalizedSrc = normalizeSrc(src)

  // Use your existing helper with the normalized src
  return generateImageUrl(normalizedSrc, imageConfig)
}

// For compatibility with the default export expected by Next.js
export default cloudflareImageLoader
